import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import SearchBox from "./SearchBox";
import TagBlogCard from "./tagBlogCard";

const TagRouteTemplate = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges.map((p) => p.node);

  return (
    <Box
      sx={{
        pt: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexWrap: "wrap",
        backgroundColor: "background.default",
      }}
    >
      <Box sx={{ mt: 4 }}>
        {/* <SearchBox /> */}
        <TagBlogCard projects={posts} />
      </Box>
    </Box>
  );
};

TagRouteTemplate.propTypes = {
  posts: PropTypes.object,
  pageContext: PropTypes.object,
};

export default TagRouteTemplate;
