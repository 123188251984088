import * as React from "react";
import { formatDistanceToNowStrict, subHours, subMinutes } from "date-fns";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CommentIcon from "@mui/icons-material/Comment";
import AccessibleLink from "./AccessibleLink";
import SocialMediaShare from "./SocialMediaShare";
import TagList from "./TagList";
import { FormattedMessage } from "react-intl";

const now = new Date();

const TagBlogCard = (props) => {
  const projects = props.projects;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //color: #f1f8ff, E2FFED, 499F9F
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        p: 3,
      }}
    >
      <Grid container spacing={3}>
        {projects.map((project, inx) => {
          return (
            <Grid item key={inx} md={4} xs={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      mt: 2,
                    }}
                  >
                    <Box sx={{ ml: 1 }}>
                      <Typography variant='h6' color='primary'>
                        <AccessibleLink to={project.frontmatter.slug}>
                          {project.frontmatter.title}
                        </AccessibleLink>
                      </Typography>
                      <Typography color='textSecondary' variant='caption'>
                        {project.frontmatter.author && "By | "}
                        {project.frontmatter.author}Updated{" "}
                        {formatDistanceToNowStrict(
                          subHours(
                            new Date(project.frontmatter.date),
                            1
                          ).getTime()
                        )}{" "}
                        ago
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    pb: 2,
                    px: 3,
                  }}
                >
                  <Typography color='textSecondary' variant='body2'>
                    {project.excerpt}
                  </Typography>
                  <TagList
                    tags={project.frontmatter.tags.slice(0, 1)}
                    color={"default"}
                    langKey={project.fields.langKey}
                  />
                </Box>

                <Divider />
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    pl: 2,
                    pr: 3,
                    py: 2,
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      m: 1,
                    }}
                  >
                    {/* <Tooltip
                      title={<FormattedMessage id='commentsTooltipButton' />}
                    >
                      <IconButton
                        onClick={handleOpen}
                        sx={{ color: "#308f96" }}
                        size='medium'
                      >
                        <CommentIcon fontSize='small' />
                      </IconButton>
                    </Tooltip> */}
                    <SocialMediaShare />
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TagBlogCard;
