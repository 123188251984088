import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Helmet from "react-helmet";
import TopBar from "../TopBar";
import {
  getCurrentLangKey,
  getLangs,
  getUrlForLang,
} from "../../langfunctions";
import { IntlProvider } from "react-intl";
import { shouldPolyfill } from "@formatjs/intl-relativetimeformat/should-polyfill";
import { Box } from "@mui/material";
import useSettings from "../../hooks/useSettings";

const LayoutTag = (props) => {
  const { settings, saveSettings } = useSettings();
  const data = props.data;
  const description = props.data.markdownRemark.frontmatter.description;
  const title = props.data.markdownRemark.frontmatter.title;
  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const homeLink = `/${langKey}/`;
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));

  async function polyfill(locale) {
    if (shouldPolyfill()) {
      // Load the polyfill 1st BEFORE loading data
      await import("@formatjs/intl-relativetimeformat/polyfill");
    }

    if (Intl.RelativeTimeFormat.polyfilled) {
      switch (locale) {
        default:
          await import("@formatjs/intl-relativetimeformat/locale-data/en");
          break;
        case "zh":
          await import("@formatjs/intl-relativetimeformat/locale-data/zh");
          break;
      }
    }
  }

  const handleChange = (field, value) => {
    saveSettings({
      ...settings,
      [field]: value,
    });
    polyfill(value);
  };

  React.useEffect(() => {
    langKey === "zh" ? handleChange("lang", "zh") : handleChange("lang", "en");
  }, []);

  const i18nMessages = require(`../../data/messages/${
    langKey || settings.lang
  }`);

  return (
    <>
      <Helmet
        key='app-head'
        defaultTitle={title}
        titleTemplate={`%s | ${title}`}
      >
        <html lang={langKey} />
        <meta name='description' content={description} />
      </Helmet>
      <IntlProvider
        locale={langKey}
        messages={i18nMessages}
        textComponent={React.Fragment}
      >
        <Box
          sx={{
            backgroundColor: "background.default",
          }}
        >
          <TopBar
            langKey={langKey}
            langs={langsMenu}
            title={`Katja's World Game`}
            icon={`Katja's World Game`}
          />
          {props.children}
          <Footer langKey={langKey} langs={langsMenu} />
        </Box>
      </IntlProvider>
    </>
  );
};

export default LayoutTag;
